import React, { useEffect } from "react";
import loader from "../resources/loader.gif";

const styles = {
  loaderDiv: {
    position: "fixed",
    padding: "auto",
    margin: "auto",
    left: "0px",
    width: "100%",
    zIndex: "9010",
    textAlign: "center",
  },
};


export default function Loader({ top }) {
  useEffect(() => {
    document.getElementById("loaderDiv").style.top = top;
  }, []);


  return (
    <div id="loaderDiv" style={styles.loaderDiv}>
      <img src={loader} alt="loading..." />
    </div>
  );
}
