const program_name = "AnyRate";
const program_version = "0.2.2408";

const Modes = {
  INPUT_MANUAL: "Input_manual",

  SERVER_ERROR: "Server_error",
  CONTENT_ERROR: "Content_error",
};

const api_address = "https://anyrate.hlaiman.com/anyrate/api";
//const api_address = "https://chat.hlaiman.com/api";
//const api_address = "http://192.168.134.34/api";
//const api_address = "http://127.0.0.1:8002/anyrate/api";

let header_writing = "AnyRate";

const current_mode = await checkRequest();

async function checkConnection() {
  try {
    let response = await fetch(api_address + "/check_connection", {
      method: "POST",
    }).then((response) => response.json());
    if (response && response["success"]) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error: ", error);
    return false;
  }
}

async function checkRequest() {
  try {
    if (!(await checkConnection())) return Modes.SERVER_ERROR;
    else return Modes.INPUT_MANUAL;
  } catch (error) {
    console.log("Error = ", error);
    return Modes.SERVER_ERROR;
  }
}

export {
  api_address,
  header_writing,

  current_mode,
  Modes,
  checkConnection,
};
