import React from "react";

const styles = {
  header: {
    fontFamily: "Bahnschrift SemiBold, Arial Narrow",
    backgroundColor: "red",
    color: "white",
    margin: "0px",
    padding: "20px 0px",
    position: "fixed",
    top: "200px",
    left: "0px",
    width: "100%",
    zIndex: "9000",
  },
};


export default function ErrorWriting({children}) {
  return (
    <h3 align="center" style={styles.header}>
      {children}
    </h3>
  );
}
