import React, { useState, useEffect } from "react";
import ErrorWriting from "./components/errorwriting";
import InputManualForm from "./components/input_manual";
import { Modes, current_mode } from "./const";

export default function App() {
  const [mode, setMode] = useState(Modes.INPUT_MANUAL);

  useEffect(() => {
    setMode(current_mode);
  }, []);

  useEffect(() => {
    if (mode != Modes.SERVER_ERROR && mode != Modes.CONTENT_ERROR) {
   //   document.cookie = `mode=${mode}; max-age=864000`;
    }
  }, [mode]);

  return (
    <>
      {mode === Modes.INPUT_MANUAL && <InputManualForm setMode={setMode} />}            
      {mode === Modes.SERVER_ERROR && (
        <ErrorWriting>Server connection error</ErrorWriting>
      )}
      {mode === Modes.CONTENT_ERROR && (
        <ErrorWriting>This content is unavailable</ErrorWriting>
      )}
    </>
  );
}
