import React, { useState } from "react";

const styles = {
  button: {
    margin: "10px",
    padding: "10px",
    borderRadius: "10px",
    borderWidth: "2px",
    fontFamily: "Bahnschrift SemiBold, Arial Black",
    color: "black",
    fontSize: "16px",
    borderStyle: "none",
    borderColor: "lightgrey",
    backgroundColor: "rgb(32,192,128)",
    outline: "1px",
    outlineOffset: "4px",
    cursor: "pointer",
  },
  buttonHover: {
    margin: "10px",    
    padding: "10px",
    borderRadius: "10px",
    borderWidth: "2px",
    fontFamily: "Bahnschrift SemiBold, Arial Black",
    color: "black",
    fontSize: "16px",
    borderStyle: "none",
    borderColor: "lightblue",
    backgroundColor: "rgb(32,224,160)",
    outline: "1px",
    outlineOffset: "4px",
    cursor: "pointer",
  },
};


export default function Button({ children, id, onClick }) {
  const [style, setButtonEnterStyle] = useState(styles.button);


  return (
    <button
      id={id}
      style={style}
      onMouseEnter={() => {
        setButtonEnterStyle(styles.buttonHover);
      }}
      onMouseLeave={() => {
        setButtonEnterStyle(styles.button);
      }}
      onClick={onClick}
    >
      <strong>{children}</strong>
    </button>
  );
}
