import React, { useState, useEffect } from "react";
import Header from "./header";
import Button from "./button";
import Input from "./input";
import Loader from "./loader";

import { api_address, header_writing, Modes, checkConnection } from "../const";

const styles = {
  div: {
    position: "relative",
    top: "125px",
    maxWidth: "640px",
    margin: "auto",
    padding: "0",
    backgroundColor: "white",
    borderRadius: "20px",
    borderColor: "rgb(230,230,230)",
    borderStyle: "none",
    borderWidth: "1px",
    textAlign: "center",
  },
  div_message: {
    position: "relative",
    ///  top: "125px",
    maxWidth: "580px",
    minHeigh: "210px",
    margin: "auto",
    marginTop: "30px",
    marginBottom: "40px",
    padding: "0px",
    backgroundColor: "rgb(32,192,128)",
    borderRadius: "0px",
    borderColor: "rgb(230,230,230)",
    borderStyle: "none",
    borderWidth: "1px",
    textAlign: "center",
  },
  div_message_error: {
    position: "relative",
    ///  top: "125px",
    maxWidth: "580px",
    minHeigh: "210px",
    margin: "auto",
    marginTop: "30px",
    marginBottom: "40px",
    padding: "0px",
    backgroundColor: "red",
    borderRadius: "0px",
    borderColor: "red",
    borderStyle: "none",
    borderWidth: "1px",
    textAlign: "center",
  },
};

export default function InputManualForm({ setMode }) {
  let [objectname, setObjectName] = useState("");
  let [objectkeywords, setObjectKeyWords] = useState();
  const [objectnameerror, setObjectNameError] = useState(false);
  const [objectkeywordserror, setObjectKeyWordsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [object_save_error, setObjectSaveError] = useState(false);

  useEffect(() => {
    setObjectNameError(!objectname);
    setDescription("");
    setObjectSaveError(false);
  }, [objectname]);

  useEffect(() => {
    setObjectKeyWordsError(!objectkeywords);
    setDescription("");
    setObjectSaveError(false);
  }, [objectkeywords]);

  useEffect(() => {
    setObjectNameError(false);
    setObjectKeyWordsError(false);    
  }, [setMode]);

  function onObjectNameKeyDown(event) {
    if (event.key === "Enter") {
      setObjectNameError(!objectname);
      document.getElementById("inputObjectKeyWords").focus();
    }
  }

  function onObjectKeyWordsKeyDown(event) {
    if (event.key === "Enter") {
      onClick("proceed");
    }
  }

  async function onClick(type) {
    if (type === "proceed") {
      if (!objectname) {
        setObjectNameError(true);
        document.getElementById("inputObjectName").focus();
        return;
      }
      if (!objectkeywords) {
        setObjectKeyWordsError(true);
        document.getElementById("inputObjectKeyWords").focus();
        return;
      }
      let response = await ObjectCreateRequest(objectname, objectkeywords);
      //let response2 = ObjectsGetRequest();
      //console.log(response);
      if (!response || !response["success"] || response["exists"]) {
        setObjectSaveError(true);
      }
      
    }

    if (type === "done") {
      if (!objectname) {
        setObjectNameError(true);
        document.getElementById("inputObjectName").focus();
        return;
      }
      if (!objectkeywords) {
        setObjectKeyWordsError(true);
        document.getElementById("inputObjectKeyWords").focus();
        return;
      }
      if (!description) {
        return;
      }
      if (object_save_error) {
        return;
      }
      let response = await ObjectSaveRequest(objectname, objectkeywords, description);
      if (response) {
        objectname = "";
        objectkeywords = "";
        document.getElementById("inputObjectName").value = "";
        document.getElementById("inputObjectKeyWords").value = "";
        setObjectNameError(false);
        setObjectKeyWordsError(false);
        setDescription("");
        document.getElementById("inputObjectName").focus();
      } else {
        setDescription("Object save error");
        setObjectSaveError(true);
      }
      //console.log(response);
    }
  }

  async function ObjectCreateRequest(objectname, objectkeywords) {
    try {
      if (!(await checkConnection())) setMode(Modes.SERVER_ERROR);

      let response = await fetch(api_address + "/description_create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          objectname: objectname,
          objectkeywords: objectkeywords,
        }),
      }).then((response) => response.json());
      setDescription(response["description"]);
      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async function ObjectSaveRequest(objectname, objectkeywords, description) {
    try {
      if (!(await checkConnection())) setMode(Modes.SERVER_ERROR);

      let response = await fetch(api_address + "/object_save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          objectname: objectname,
          objectkeywords: objectkeywords,
          objectdescription: description,
        }),
      }).then((response) => response.json());

      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async function ObjectsGetRequest() {
    try {
      if (!(await checkConnection())) setMode(Modes.SERVER_ERROR);

      let response = await fetch(api_address + "/objects_get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
       //   search_string: "rgvrgfetg"       
        }),
      }).then((response) => response.json());
      console.log(response)
      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }

  return (
    <>
      {loading && <Loader top="200px" />}
      <Header>{header_writing + ": manual object input "}</Header>
      <div style={styles.div}>
        <p style={{ padding: "10px" }}>
          <Input
            id={"inputObjectName"}
            ph={"Object name (4 words max)"}
            error={objectnameerror}
            onValueChange={setObjectName}
            onKeyDown={onObjectNameKeyDown}
          />
        </p>
        <p style={{ padding: "10px" }}>
          <Input
            id={"inputObjectKeyWords"}
            ph={"Object key words"}
            error={objectkeywordserror}
            onValueChange={setObjectKeyWords}
            onKeyDown={onObjectKeyWordsKeyDown}
          />
        </p>
        <div style={object_save_error ? styles.div_message_error : styles.div_message}>
          <p style={{ padding: "10px", color: "white" }}>
            <strong>Name: </strong>
            {objectname === "" ? "---" : objectname}
            <strong> Description: </strong>
            {description === "" ? "---" : description}
          </p>
        </div>
        <p>
          <Button onClick={() => onClick("proceed")}>Proceed</Button>
        </p>
        <p>
          <Button onClick={() => onClick("done")}>Done</Button>
        </p>
      </div>
    </>
  );
}
