import React, { useState } from "react";

const styles = {
  input: {
    width: "90%",
    margin: "0",
    padding: "10px",
    borderRadius: "10px",
    borderWidth: "2px",
    fontFamily: "Arial",
    fontSize: "18px",
    borderStyle: "solid",
    backgroundColor: "rgb(250, 250, 250)",
    borderColor: "rgb(32,192,128)",
    outline: "1px",
    outlineOffset: "4px",
  },
  inputFocus: {
    width: "90%",
    margin: "0",
    padding: "10px",
    borderRadius: "10px",
    borderWidth: "2px",
    fontFamily: "Arial",
    fontSize: "18px",
    borderStyle: "solid",
    borderColor: "rgb(32,224,160)",
    outline: "1px",
    outlineOffset: "4px",
  },
  inputError: {
    width: "90%",
    margin: "0",
    padding: "10px",
    borderRadius: "10px",
    borderWidth: "2px",
    fontFamily: "Arial",
    fontSize: "18px",
    borderStyle: "solid",
    borderColor: "red",
    outline: "1px",
    outlineOffset: "4px",
  },
};


export default function Input({ id, onValueChange, onKeyDown, ph, type, min, max, step, error, tip}) {
  const [style, setStyle] = useState(styles.input);

  
  return (
    <label title={tip}>
    <input
      id={id}
      placeholder={ph}
      type={type}
      min={min}
      max={max}
      step={step}
      style={error ? styles.inputError : style}
      onFocus={() => {
        setStyle(styles.inputFocus);
      }}
      onBlur={() => {
        setStyle(styles.input);
      }}
      onChange={(event) => {
        onValueChange(event.target.value);
      }}
      onKeyDown={ onKeyDown }
    />
    </label>
  );
}
