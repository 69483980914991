import React from "react";

const styles = {
  header: {
    fontFamily: "Bahnschrift SemiBold, Arial Narrow",
    backgroundColor: "white",
    color: "rgb(32,192,128)",
    margin: "0px",
    padding: "20px 0px",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    zIndex: "9000",
  },
};


export default function Header({children}) {
  return (
    <h2 id={"headerMain"} align="center" style={styles.header}>
      {children}
    </h2>
  );
}
